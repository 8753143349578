// Core
import { useRouter } from "next/navigation";
import { observer } from "mobx-react-lite";

// Domain
import { useLoginCreate } from "@/client/domains/auth/signin/hooks/use-login";

// Components
import { SigninForm } from "@/client/domains/auth/signin/components/signin-form";
import { SigninFormInfo } from "@/client/domains/auth/signin/components/signin-form-info/signin-form-info";
import { Modal } from "@/client/components/modal";

// Hooks
import { useModalCtrl } from "@/client/hooks/use-modal-ctrl";

// Utils
import st from "./styles.module.css";
import { routes } from "@/client/definitions/book";
import { MODALS_BOOK } from "@/client/definitions/modals";

const MODAL_NAME = MODALS_BOOK.forgotPassword;

export const SigninRequest = observer(() => {
  const router = useRouter();
  const { activeModals, setModalStateByName } = useModalCtrl();
  const { loadingLoginCreate, onLogin } = useLoginCreate();

  const handleRedirectToSignup = () => router.push(routes.signup);
  const onCloseModal = () => setModalStateByName(MODAL_NAME, false);
  const onOpenModal = () => setModalStateByName(MODAL_NAME, true);

  return (
    <div className={st["signin-request"]}>
      <SigninForm
        onSubmit={onLogin}
        loading={loadingLoginCreate}
        onRedirectToSignup={handleRedirectToSignup}
        testId="signin-page-signin-form"
        onOpenForgotPasswordModal={onOpenModal}
      />
      <SigninFormInfo />
      <Modal
        modalName={MODAL_NAME}
        isOpen={activeModals[MODAL_NAME]}
        onClose={onCloseModal}
        width={448}
      />
    </div>
  );
});
