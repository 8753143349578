// Definitions
import type { HttpResponseApiDataType } from "@/common/types/http";

// Feature
import type { InternalLoginResponseType, LoginRequestParamsType } from "./types";

// Utils
import { http } from "@/client/transport/http";

export const loginApi = Object.freeze({
  loginRequestCreate: (payload: LoginRequestParamsType) => {
    const { data } = payload;
    return http.post<HttpResponseApiDataType<InternalLoginResponseType>>("/auth/login", data);
  },
});
