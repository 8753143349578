// Core
import { useState } from "react";

type UseToggleType = {
  isToggle: boolean;
  onToggle: (value: boolean) => void;
};

export const useToggle = (open?: boolean): UseToggleType => {
  const [isToggle, onToggle] = useState(!!open);

  return {
    isToggle,
    onToggle,
  };
};
