// Core
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
import { clone } from "ramda";
import { useRouter } from "next/navigation";

// Definitions
import type { FormSubmitParams } from "@/client/components/form/form.types";
import type { HttpResponseAxiosDataType, HttpResponseAxiosErrorType } from "@/common/types/http";

// Hooks
import { useNotification } from "@/client/hooks/use-notification";

// Feature
import { LoginRequestType, LoginRequestParamsType, InternalLoginResponseType } from "../types";
import { loginApi } from "../infrastructure";
import { loginKeys } from "../query";

// Utils
import { fillServerErrorsToForm } from "@/client/utils/forms";
import { getHttpErrorMessage } from "@/client/services/notifications/notifications-msg";
import { routes } from "@/client/definitions/book";

type OnLoginCbType = (values: FormSubmitParams<LoginRequestType>) => void;
type UseLoginCreateType = {
  loadingLoginCreate: boolean;
  onLogin: OnLoginCbType;
};

const fetcher = (_key: string, { arg }: { arg: LoginRequestParamsType }) =>
  loginApi.loginRequestCreate(arg);

export const useLoginCreate = (): UseLoginCreateType => {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const { showError } = useNotification();
  const loginRequestMutation = useSWRMutation<
    HttpResponseAxiosDataType<InternalLoginResponseType>,
    HttpResponseAxiosErrorType,
    string,
    LoginRequestParamsType
  >(loginKeys.login, fetcher);
  const onLogin = useCallback((data: FormSubmitParams<LoginRequestType>) => {
    const requestData = { data: clone(data.values) };

    loginRequestMutation
      .trigger(requestData)
      .then(() => {
        router.push(routes.dashboard);
        return router.refresh();
      })
      .catch((e: HttpResponseAxiosErrorType) => {
        const error = e.response?.data;
        if (error && error.errors) {
          return fillServerErrorsToForm({
            i18n,
            errors: error.errors,
            setError: data?.acts?.setError,
          });
        }
        showError(getHttpErrorMessage(t));
      });
  }, []);

  return {
    loadingLoginCreate: loginRequestMutation.isMutating,
    onLogin,
  };
};
