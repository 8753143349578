import { NotificationMessage } from "@/client/components/ui/Notification";

export const httpErrorMessage: NotificationMessage = {
  title: "Error",
  desc: "Something went wrong. Please, try again later",
};
export const httpSuccessMessage: NotificationMessage = {
  title: "Success",
  desc: "",
};
export const httpErrorForbiddenMessage: NotificationMessage = {
  title: "Forbidden",
  desc: "Access is denied",
};
export const httpErrorMessageNotFound: NotificationMessage = {
  title: "Error",
  desc: "Article not found",
};
export const httpErrorMessageNoData: NotificationMessage = {
  title: "No Data",
  desc: "Data for the app was not loaded successfully",
};
export const httpSuccessMessageUpdate: NotificationMessage = {
  title: "Success",
  desc: "You data was updated successfully!",
};
