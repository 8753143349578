"use client";

// Core
import type { PropsWithChildren } from "react";
import cx from "classnames";

// Components
import { Grid } from "antd";

// Utils
import st from "./styles.module.css";

type ContainerProps = PropsWithChildren & {
  type?: "full" | "max";
  testId?: string;
  className?: string;
};

export const LayoutContainer = ({
  type,
  children = null,
  className,
  testId = "layout-container",
}: ContainerProps) => {
  const { md = true, lg = true } = Grid.useBreakpoint();
  const containerStyle = cx(
    st.container,
    {
      [st["container--mobile"]]: !md,
      [st["container--tablet"]]: !lg,
      [st[`container--${type}`]]: type,
    },
    className,
  );

  return (
    <div className={containerStyle} data-testid={testId}>
      {children}
    </div>
  );
};
