// Core
import type { ReactNode } from "react";
import { forwardRef, BaseSyntheticEvent } from "react";
import cx from "classnames";

// Components
import { Input, type InputRef } from "antd";

// Definitions
import { InputProps } from "antd";

// Utils
import st from "./styles.module.css";
import { formatWithMask } from "@/client/services/format-with-mask/format-with-mask";

export type InputRefBaseType = InputRef | null;

export interface IInputBase extends InputProps {
  placeholder?: string;
  maxLength?: number;
  autoComplete?: "on" | "off";
  hasAutoComplete?: boolean;
  hasDisabled?: boolean;
  size?: "middle" | "large" | "small";
  shape?: "reg-num";
  isTextCentered?: boolean;
  hintText?: ReactNode;
  value?: string | readonly string[] | number | undefined;
  onChange?: (event: BaseSyntheticEvent) => void;
  mask?: string;
}

export const InputBase = forwardRef<InputRefBaseType, IInputBase>((props, ref) => {
  const {
    className,
    hasAutoComplete = false,
    hasDisabled = false,
    size = "large",
    type = "text",
    shape,
    maxLength = 100,
    placeholder = "Input",
    isTextCentered = false,
    onChange,
    value = "",
    hintText = null,
    onBlur,
    mask,
    ...rest
  } = props;
  const autoCompleteValue = hasAutoComplete ? "on" : "off";

  const inputStyle = cx(
    st.input,
    {
      [st.disabled]: hasDisabled,
      [st.number]: type === "number",
      [st.centered]: isTextCentered,
    },
    shape && [st[`shape-${shape}`]],
    className,
  );

  const formattedValue = mask ? formatWithMask({ value: String(value), mask }).maskedValue : value;

  return (
    <>
      <Input
        {...rest}
        type={type}
        className={inputStyle}
        size={size}
        autoComplete={autoCompleteValue}
        disabled={hasDisabled}
        ref={ref}
        maxLength={maxLength}
        placeholder={placeholder}
        value={formattedValue}
        onChange={onChange}
        onBlur={onBlur}
      />
      {hintText}
    </>
  );
});
InputBase.displayName = "InputBase";
