// Store
import { useStore } from "@/client/hooks/use-store";

// Definitions
import { UserCountryCodes } from "@/client/domains/user/types";

type UseUserCountryType = () => {
  country: keyof typeof UserCountryCodes;
};

export const useUserCountry: UseUserCountryType = () => {
  const {
    geo: { country },
  } = useStore();

  return { country };
};
