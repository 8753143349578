import IMask from "imask";

export const PHONE_INPUT_MASK = "pp pp pp pp pp pp p";

const maskDefinitions = {
  p: /[0-9+]/,
};

interface FormatWithMaskParams {
  value: string;
  mask: string;
}
export const formatWithMask = ({ value, mask }: FormatWithMaskParams) => {
  const masked = IMask.createMask({
    mask,
    definitions: maskDefinitions,
  });
  masked.resolve(value);
  const { value: maskedValue, unmaskedValue } = masked;
  return { maskedValue, unmaskedValue };
};

export const formatWithPhoneNumberMask = (value: string) => {
  return formatWithMask({ value, mask: PHONE_INPUT_MASK });
};
